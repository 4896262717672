import React from "react";
import Box from "../../Components/Box/Box";

const Kontakti = () => {
  return (
    <div>
      <Box pageTitle="KONTAKTI" />
      <iframe
        title="Map"
        height="800"
        style={{ width: "100%", border: "0" }}
        loading="lazy"
        allowfullscreen
        referrerpolicy="no-referrer-when-downgrade"
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCVbsKMxsXQxF17CUjnbClFhaOHG1kcSuY
    &q=Auto+Energjia"
      ></iframe>
    </div>
  );
};

export default Kontakti;
