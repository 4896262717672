import React, { useState } from "react";
import logo from "../../assets/Logo.png";
import styles from "./Header.module.scss";
import whatsAppImg from "../../assets/whatsAppImg.png";

const Header = () => {
  const currentUrl = window.location.pathname;
  const [opened, setOpened] = useState(false);

  if (currentUrl === "/") {
    return <></>;
  }

  return (
    <>
    <img src={whatsAppImg} onClick={() => window.open("https://chatwith.io/s/38338344777775","_self")} alt="" className=' size-[100px] fixed right-[10px] bottom-[10px] z-[10000] ' />
    <div
      style={{ height: opened ? "300px" : "", backdropFilter:opened?"blur(9px)":"", backgroundColor:opened?"rgba(0,0,0,0.7)":"" }}
      className=" absolute px-[50px] h-[56px] z-[100] transition-all duration-[0.5s] overflow-hidden md:h-[40px] md:px-[15px] md:py-[5px] flex items-center justify-between md:justify-start w-full md:flex-col "
    >
      <div className=" hidden md:flex items-center h-[30px] py-[4px] w-[100%] justify-between ">
        <img src={logo} alt="" className=" h-[22px] " />
        <div
          onClick={() => setOpened(!opened)}
          className=" flex gap-[3px] flex-col cursor-pointer "
        >
          <div
            style={{ opacity: opened ? "0" : "1" }}
            className=" transition-all duration-[0.3s] w-[20px] h-[3px] bg-[#0094da] "
          />
          <div className=" w-[20px] h-[3px] relative ">
            <div
              style={{ rotate: opened ? "45deg" : "0deg" }}
              className="  transition-all duration-[0.3s] w-[100%] h-[100%] bg-[#0094da] absolute "
            />
            <div
              style={{ rotate: opened ? "-45deg" : "0deg" }}
              className="  transition-all duration-[0.3s] w-[100%] h-[100%] bg-[#0094da] absolute "
            />
          </div>
          <div
            style={{ opacity: opened ? "0" : "1" }}
            className="  transition-all duration-[0.3s] w-[20px] h-[3px] bg-[#0094da] "
          />
        </div>
      </div>
      <div className=" md:flex-col flex items-center gap-[25px] md:gap-[5px] md:mt-[20px] ">
        <img src={logo} alt="" className=" md:hidden h-[12px] mr-[20px] " />
        <a
          className={currentUrl === "/home" ? " font-[700] text-[#0094da]" : ""}
          href="/"
        >
          HOME
        </a>
        <a
          className={
            currentUrl === "/sherbimet" ? " font-[700] text-[#0094da]" : ""
          }
          href="/sherbimet"
        >
          SHËRBIMET
        </a>
        <a
          className={
            currentUrl === "/cakto-termin" ? " font-[700] text-[#0094da]" : ""
          }
          href="/cakto-termin"
        >
          CAKTO TERMIN
        </a>
        <a
          className={
            currentUrl === "/veturat" ? " font-[700] text-[#0094da]" : ""
          }
          href="/veturat"
        >
          VETURAT
        </a>

        <a
          className={
            currentUrl === "/galeria" ? " font-[700] text-[#0094da]" : ""
          }
          href="/galeria"
        >
          GALERIA
        </a>
        <a
          className={
            currentUrl === "/rreth-nesh" ? " font-[700] text-[#0094da]" : ""
          }
          href="/rreth-nesh"
        >
          RRETH NESH
        </a>
        <a
          className={
            currentUrl === "/kontakti" ? " font-[700] text-[#0094da]" : ""
          }
          href="/kontakti"
        >
          KONTAKTI
        </a>
      </div>
      <div className=" md:mt-[5px] flex items-center gap-[5px] ">
        <svg
          onClick={() => window.open("https://www.facebook.com/energjia/")}
          fill="#ffffff"
          height="25px"
          width="25px"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-143 145 512 512"
          className=" hover:fill-[#0094da] duration-[0.1s] "
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <g>
              {" "}
              <path d="M113,145c-141.4,0-256,114.6-256,256s114.6,256,256,256s256-114.6,256-256S254.4,145,113,145z M272.8,560.7 c-20.8,20.8-44.9,37.1-71.8,48.4c-27.8,11.8-57.4,17.7-88,17.7c-30.5,0-60.1-6-88-17.7c-26.9-11.4-51.1-27.7-71.8-48.4 c-20.8-20.8-37.1-44.9-48.4-71.8C-107,461.1-113,431.5-113,401s6-60.1,17.7-88c11.4-26.9,27.7-51.1,48.4-71.8 c20.9-20.8,45-37.1,71.9-48.5C52.9,181,82.5,175,113,175s60.1,6,88,17.7c26.9,11.4,51.1,27.7,71.8,48.4 c20.8,20.8,37.1,44.9,48.4,71.8c11.8,27.8,17.7,57.4,17.7,88c0,30.5-6,60.1-17.7,88C309.8,515.8,293.5,540,272.8,560.7z"></path>{" "}
              <path d="M146.8,313.7c10.3,0,21.3,3.2,21.3,3.2l6.6-39.2c0,0-14-4.8-47.4-4.8c-20.5,0-32.4,7.8-41.1,19.3 c-8.2,10.9-8.5,28.4-8.5,39.7v25.7H51.2v38.3h26.5v133h49.6v-133h39.3l2.9-38.3h-42.2v-29.9C127.3,317.4,136.5,313.7,146.8,313.7z"></path>{" "}
            </g>{" "}
          </g>
        </svg>
        <svg
          onClick={() => window.open("https://www.instagram.com/autoenergjia/")}
          fill="#ffffff"
          height="25px"
          width="25px"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-143 145 512 512"
          className=" hover:fill-[#0094da] duration-[0.1s] "
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <g>
              {" "}
              <path d="M113,145c-141.4,0-256,114.6-256,256s114.6,256,256,256s256-114.6,256-256S254.4,145,113,145z M272.8,560.7 c-20.8,20.8-44.9,37.1-71.8,48.4c-27.8,11.8-57.4,17.7-88,17.7c-30.5,0-60.1-6-88-17.7c-26.9-11.4-51.1-27.7-71.8-48.4 c-20.8-20.8-37.1-44.9-48.4-71.8C-107,461.1-113,431.5-113,401s6-60.1,17.7-88c11.4-26.9,27.7-51.1,48.4-71.8 c20.9-20.8,45-37.1,71.9-48.5C52.9,181,82.5,175,113,175s60.1,6,88,17.7c26.9,11.4,51.1,27.7,71.8,48.4 c20.8,20.8,37.1,44.9,48.4,71.8c11.8,27.8,17.7,57.4,17.7,88c0,30.5-6,60.1-17.7,88C309.8,515.8,293.5,540,272.8,560.7z"></path>{" "}
              <path d="M191.6,273h-157c-27.3,0-49.5,22.2-49.5,49.5v52.3v104.8c0,27.3,22.2,49.5,49.5,49.5h157c27.3,0,49.5-22.2,49.5-49.5V374.7 v-52.3C241,295.2,218.8,273,191.6,273z M205.8,302.5h5.7v5.6v37.8l-43.3,0.1l-0.1-43.4L205.8,302.5z M76.5,374.7 c8.2-11.3,21.5-18.8,36.5-18.8s28.3,7.4,36.5,18.8c5.4,7.4,8.5,16.5,8.5,26.3c0,24.8-20.2,45.1-45.1,45.1C88,446.1,68,425.8,68,401 C68,391.2,71.2,382.1,76.5,374.7z M216.1,479.5c0,13.5-11,24.5-24.5,24.5h-157c-13.5,0-24.5-11-24.5-24.5V374.7h38.2 c-3.3,8.1-5.2,17-5.2,26.3c0,38.6,31.4,70,70,70c38.6,0,70-31.4,70-70c0-9.3-1.9-18.2-5.2-26.3h38.2V479.5z"></path>{" "}
            </g>{" "}
          </g>
        </svg>
      </div>
    </div>
    </>
  );
};

export default Header;
