import React from "react";

const OfferPreview = ({ data, square, showPrice }) => {
  if( !data ) {
    return <></>
  }
  function formatPrice(str, divider) {
    if(!str) return
    let formattedStr = str.toString(); //converts number to str
    let revArray = []; //instances an empty array
    for (let char of formattedStr) {
      //loops through the str to get each character
      revArray.push(char); //pushes character to earlier array
    }
    revArray.reverse(); //reverses the array
    let array = [];
    for (let index in revArray) {
      //loops over revArray for index
      array.push(revArray[index]); //pushes the character from earlier array to new array
      if (index % 3 === 2) {
        //if the 3rd modulus of index equals 2, push a space
        array.push(divider);
      }
    }
    array.reverse(); //reverse the new array to get back to forwards
    let finalStr = "";
    for (let char of array) {
      //finally loop over the new array and push each character into a string
      finalStr += char;
    }

    if (finalStr[0] === divider) {
      finalStr = finalStr.replace(divider, "");
    }

    return finalStr;
  }

  return (
    <div
      onClick={() => window.open(`/veturat/${data?.documentId}`, "_self")}
      className="ml-[15px] mr-[15px] flex-shrink-0 max-w-[25vw] min-w-[250px] md:max-w-[100vw] mb-[25px] cursor-pointer hover:translate-y-[-2%] duration-[0.1s] hover:shadow-lg "
    >
      <img
        src={data?.gallery[0]?.url}
        alt=""
        style={{
          aspectRatio: square ? "1" : "2",
        }}
        className="w-[100%] object-cover"
      />
      <div className=" border-[#e3e3e3] border-[1px] border-solid ">
          <>
            <h3 className=" text-[#3f3e40] py-[10px] text-[28px] font-[700] border-[#e3e3e3] border-solid border-b-[1px] ">
              {data?.offerTitle}
            </h3>
        {!data?.sold ? (
            <div className=" flex justify-between py-[15px] h-[60px] px-[14px] ">
              <p className=' text-[#686868] text-[17px] ' >{data?.year}</p>
              <p className=' text-[#686868] text-[17px] ' >{formatPrice(data?.km,',')} km</p>
            </div>
            ) : (
              <div style={{background:"linear-gradient(0deg, rgb(222 0 36 / 12%) 0%, rgba(222,0,36,0) 100%)"}} className=" flex justify-center py-[15px] h-[60px] px-[14px] ">
                <p className=' text-[#de0f30] text-[17px] h-[100%] font-[700] ' >E SHITUR</p>
              </div>
            )}
            {showPrice && !data?.sold ? (
              <h3 className=" text-[#0094da] py-[15px] px-[14px] h-[60px] font-[700] border-[#e3e3e3] border-solid border-t-[1px] ">
              €{formatPrice(data?.price, ",")}.00
              </h3>
            ) : (
              <></>
            )}
          </>
      </div>
    </div>
  );
};

export default OfferPreview;
