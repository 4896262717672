import React, { useEffect, useState } from 'react'
import Box from '../../Components/Box/Box'
import { getGallery } from '../../utils/api'

const Galeria = () => {
  const [apiData,setData] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getGallery()
        setData(data.data.images)
      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
  },[])
  return (
    <div>
      <Box pageTitle={"GALERIA"} />
      <div className=' flex flex-wrap mb-[100px] '>
        {apiData.map((data,index) => (
          <img alt='' src={data.url} key={index} className=' w-[25%] md:w-[50%] md:aspect-square object-cover hover:scale-95 transition-scale duration-[0.5s] '/>
        ))}
      </div>
    </div>
  )
}

export default Galeria
