import React, { useState } from "react";
import styles from "./Button.module.scss";

const Button = ({ children, backgroundColor, func, disabled }) => {
  const [mouseHover, setMouseHover] = useState(false);

  return (
    <button
      disabled={disabled}
      onMouseEnter={() => setMouseHover(true)}
      onMouseLeave={() => setMouseHover(false)}
      onClick={func}
      className={styles.buttonWrapper}
      style={{
        backgroundColor: mouseHover ? "#fff" : backgroundColor,
        color: !mouseHover ? "#fff" : backgroundColor,
        borderColor: backgroundColor,
      }}
    >
      {children}
    </button>
  );
};

export default Button;
