import React, { useEffect, useState } from "react";
import Box from "../../Components/Box/Box";
import aboutStoreBackground from "../../assets/aboutStoreBG.jpg";
import mercedesLogo from "../../assets/mercedesLogo.png";
import bmwLogo from "../../assets/bmwLogo.png";
import audiLogo from "../../assets/audiLogo.png";
import volkswagenLogo from "../../assets/volkswagenLogo.png";
import aboutBlurredBG from "../../assets/aboutBlurredBG.png";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Navigation } from "swiper/modules";
import { getReviews } from "../../utils/api";

const About = () => {
  const [apiData,setData] = useState([{}])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getReviews()
        setData(data.data)
      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
  },[])

  return (
    <div className="flex flex-col items-center w-[100%] ">
      <Box pageTitle={"RRETH NESH"} backgroundColor={"#0094da"}>
        <h1 className="text-white text-center md:text-[45px]">
          MIRË SE VINI
          <br />
          TEK AUTO ENERGJIA
        </h1>
        <p className=" text-white text-center text-[22px] md:text-[18px] mb-[10px] ">
          Auto Energjia është kompani e cila bën shitjen dhe servisimin
          profesional të automjeteve të markave të njohura botërore si
          Mercedes-Benz, BMW dhe Volkswagen.
        </p>
        <p className=" text-white text-center text-[22px] md:text-[18px] ">
          Ne ju falenderojmë për besimin dhe bashkëpunimin tuaj për 20 vite!
        </p>
      </Box>
      <div className=" mt-[30px] text-center flex flex-col w-[100%] items-center ">
        <h1 className='md:text-[40px] ' >HISTORIKU</h1>
        <h4 className=" w-[60%] md:w-[90%] my-[10px] md:text-[18px] ">
          Me dashurinë ndaj punës dhe aftësitë mekanike që i posedonte z. Driton
          Salihu, ai arriti të hapte një ndër showroom-ët më të mëdhenjë dhe
          modern i cili fillet i kishte tek një ofiçinë mekanike të quajtur
          "Energjia".
        </h4>
        <p className=" w-[60%] md:w-[90%]  my-[5px] md:text-[18px] ">
          Etapa e parë e Auto Energjisë filloi më 13 qershor të vitit 1994, e
          fokusuar vetëm në një shërbim i cili ishte mjeshtëria elektrike e
          veturave, periudhë kjo e cila hodhi themelet e Auto Energjisë së
          sotme. Duke e marr parasysh kohën e pasluftës në të cilën vendi ynë
          gjindej në atë kohë, ai ishte pronari dhe punëtori i vetëm
          njëkohësisht i cili me vetëm mjetet elementare të punës arriti të
          krijonte një klientelë të madhe.
        </p>
        <p className=" w-[60%] md:w-[90%]  my-[5px] md:text-[18px]">
          Duke e parë rritjen e vazhdueshme të kërkesës dhe dëshira për t'iu
          përshtatur nevojave të klientëve, z. Driton Salihu e pa të arsyeshme
          ndrrimin e lokacionit. Etapa e dytë e Auto Energjisë fillon në një
          lokacion të ri ku "Energjia" vazhdon të zgjerohet profesionalisht sa i
          përket rritjes së shërbimeve dhe stafit. Puna e pandalshme dhe rritja
          e kërkesës shtyen pronarin detyrimisht që të vendoset në një lokacion
          edhe më të madh.
        </p>
        <p className=" w-[60%] md:w-[90%]  my-[5px] md:text-[18px] ">
          Etapa e tretë fillon në vitin 2006 kur "Energjia" zhvendoset
          përfundimisht në Bërnicë dhe me arritjen e posedimit e të gjitha
          mjeteve adekuate të punës dhe mundësinë e ofrimit të të gjitha
          shërbimeve automobilistike, ajo merr emrin "Auto Energjia".
        </p>
        <div className=" my-[30px] flex flex-col justify-center items-center relative w-[100%] h-[430px] ">
          <img
            className=" w-[100%] h-[100%] object-cover absolute z-[-1] "
            src={aboutStoreBackground}
            alt=""
          />
          <h3 className="text-white font-[400] md:text-[18px] ">AUTO ENERGJIA,</h3>
          <h1 className=" text-white w-[60%] md:w-[90%] md:text-[40px] ">
            DEFINICIONI I VËRTETË I ENERGJISË!
          </h1>
        </div>
        <p className=" w-[60%] md:w-[90%]  my-[5px] ">
          Sot Auto Energjia ka arritur standardin më të ri me një numër të madh
          të shërbimeve dhe punonjësve, në hap me trendet dhe standardet
          botërore automobilistike.
        </p>
        <h3 className=" w-[60%] md:w-[90%] mt-[50px] mb-[5px] ">
          Shërbimet që sot Auto Energjia me shumë profesionalizëm ju ofron janë
          këto:
        </h3>
        <p className=" w-[60%] md:w-[90%]  my-[5px] ">
          Servisimi, Auto Detailing, Shitja e Veturave, Ngjyrosja, Auto Larje,
          Retrofit & Chip
        </p>
        <h1 className=" text-[46px] w-[60%] md:w-[90%] mt-[30px] mb-[20px] ">
          PARTNERËT TANË
        </h1>
        <div className="flex gap-[20px] h-[140px] md:h-[60px] bg-[#f4f4f4] px-[20px] py-[10px] relative ">
          <img
            className=" h-[100%] flex-shrink-0 "
            src={mercedesLogo}
            alt=""
          />
          <img className=" h-[100%] flex-shrink-0 " src={bmwLogo} alt="" />
          <img className=" h-[100%] flex-shrink-0 " src={audiLogo} alt="" />
          <img
            className=" h-[100%] flex-shrink-0 "
            src={volkswagenLogo}
            alt=""
          />
        </div>
        <div className="relative w-[80%] md:w-[95%] min-h-[420px] mb-[50px] mt-[50px]">
          <img
            className=" absolute z-[-1] w-[100%] h-[80%] "
            src={aboutBlurredBG}
            alt=""
          />
          <h1 className=" m-[50px] md:m-[10px] md:mt-[20px] text-[55px] md:text-[30px] text-[#3f3e40] ">
            KLIENTËT TANË TË KËNAQUR
          </h1>
          <div id="aboutReviewsLeft" className=" z-10 absolute bottom-0 flex items-center justify-center h-[320px] w-[12.5%] left-[12.5%] md:left-[2.5%] ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 19 18"
              width="19"
              height="18"
              fill="#83807b"
              style={{ transform: "rotate(180deg)" }}
            >
              <g id="Testemonials">
                <path
                  id="Arrow"
                  class="arrow1"
                  d="M0.98 8L18.02 8C18.56 8 19 8.44 19 8.98C19 9.53 18.56 9.97 18.02 9.97L0.98 9.97C0.44 9.97 0 9.53 0 8.98C0 8.44 0.44 8 0.98 8ZM10.73 0.28L18.68 8.22C19.08 8.62 19.08 9.27 18.68 9.66C18.28 10.06 17.64 10.06 17.24 9.66L9.28 1.73C8.89 1.33 8.89 0.68 9.28 0.28C9.68 -0.12 10.33 -0.12 10.73 0.28ZM10.73 17.68L18.68 9.74C19.08 9.35 19.08 8.7 18.68 8.3C18.28 7.9 17.64 7.9 17.24 8.3L9.28 16.24C8.89 16.64 8.89 17.28 9.28 17.68C9.68 18.08 10.33 18.08 10.73 17.68Z"
                ></path>
              </g>
            </svg>
          </div>
          <div id="aboutReviewsRight" className=" z-10 absolute bottom-0 flex items-center justify-center h-[320px] w-[12.5%] right-[12.5%] md:right-[2.5%] ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 19 18"
              width="19"
              height="18"
              fill="#83807b"
            >
              <g id="Testemonials">
                <path
                  id="Arrow"
                  class="arrow1"
                  d="M0.98 8L18.02 8C18.56 8 19 8.44 19 8.98C19 9.53 18.56 9.97 18.02 9.97L0.98 9.97C0.44 9.97 0 9.53 0 8.98C0 8.44 0.44 8 0.98 8ZM10.73 0.28L18.68 8.22C19.08 8.62 19.08 9.27 18.68 9.66C18.28 10.06 17.64 10.06 17.24 9.66L9.28 1.73C8.89 1.33 8.89 0.68 9.28 0.28C9.68 -0.12 10.33 -0.12 10.73 0.28ZM10.73 17.68L18.68 9.74C19.08 9.35 19.08 8.7 18.68 8.3C18.28 7.9 17.64 7.9 17.24 8.3L9.28 16.24C8.89 16.64 8.89 17.28 9.28 17.68C9.68 18.08 10.33 18.08 10.73 17.68Z"
                ></path>
              </g>
            </svg>
          </div>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: true,
            }}
            loop={true}
            pagination={{
              clickable: true,
            }}
            navigation={{
              nextEl:"#aboutReviewsRight",
              prevEl:"#aboutReviewsLeft",
            }}
            modules={[Autoplay, Navigation]}
            className="h-[320px] md:!px-[10%] px-[25%] "
          >
            {apiData.map((data, index) => (
              <SwiperSlide key={index} className="px-[25%] md:!px-[10%] mt-[30px] ">
                <div className=" pt-[40px] px-[20px] pb-[20px] border border-stone-200 border-solid rounded-md min-h-[250px] bg-white flex flex-col items-center ">
                  <h3>{data.companyName}</h3>
                  <p className=" mb-[20px] text-[#9fa3a7] text-[13px] tracking-widest font-[700] border-solid border-[#eaeaea] border-b-[3px] ">
                    Klient i kënaqur
                  </p>
                  <p>{data.review}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default About;