import React from "react";
import FiveoffImg from "../../assets/Service5%off.png";
import background from "../../assets/background.png";


const Box = ({
  pageTitle,
  children,
  backgroundColor,
  FiveOff,
  boxDisabled,
}) => {
  if (!backgroundColor) {
    backgroundColor = `#3f3e40`;
  }
  if (FiveOff === undefined) {
    FiveOff = false;
  }

  if (!children) {
    boxDisabled = true
  }
  
  return (
    <div className=" flex items-center flex-col w-[100%] ">
      <div className=" flex justify-center items-center w-[100%] h-[300px] " style={{background:`url(${background})`}}>
        <h1 className="text-white text-center md:text-[50px]">{pageTitle}</h1>
      </div>
      {boxDisabled ? (
        <></>
      ) : (
        <div
          style={{ backgroundColor: backgroundColor }}
          className={` max-w-[900px] md:w-[90%] flex items-center justify-center flex-col p-[40px] md:px-[10px] relative px-[80px] mt-[-40px] ml-[40px] mr-[40px] `}
        >
          {FiveOff ? (
            <img
              className="absolute w-[90px] md:w-[45px] left-[10px] h-[103px] md:h-[51.5px] top-[-15px] md:top-[-7px] "
              src={FiveoffImg}
              alt=""
            />
          ) : (
            <></>
          )}
          {children}
        </div>
      )}
    </div>
  );
};

export default Box;
