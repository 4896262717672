import React, { useEffect, useState } from "react";
import Box from "../../Components/Box/Box";
import Button from "../../Components/Button/Button";
import InfoBar from "../../Components/InfoBar/InfoBar";
import OfferPreview from "../../Components/OfferPreview/OfferPreview";
import { getOffers } from "../../utils/api";
import serviceHero from "../../assets/serviceImg.jpeg";
import serviceGuy from "../../assets/guydoingService.jpeg";
import clock from "../../assets/clock.png";
import hand from "../../assets/hand.png";
import car1 from "../../assets/Car1Vector.png";
import car1back from "../../assets/car1back.png";
import car2 from "../../assets/car2.png";
import car2back from "../../assets/car2back.png";
import car3 from "../../assets/car3.png";
import car3back from "../../assets/car3back.png";
import car4 from "../../assets/car4.png";
import car4back from "../../assets/car4back.png";
import gallery1 from "../../assets/serviceGallery1.jpeg";
import gallery2 from "../../assets/serviceGallery2.jpeg";
import gallery3 from "../../assets/serviceGallery3.jpeg";
import gallery4 from "../../assets/serviceGallery4.jpeg";

const Service = () => {
  const [apiData, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getOffers();
        setData([data.data[0], data.data[1], data.data[2]]);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="pt-[85px] bg-black">
      <div className="  relative h-[480px] z-[10] flex flex-col justify-center overflow-hidden ">
        <img src={serviceHero} className=" absolute z-[-1] right-0 " alt="" />
        <h2 className="z-5 text-white font-[900] text-[40px] leading-[48px] mb-[20px] ">
          SERVISI
        </h2>
        <p className=" text-white w-[630px] z-5 font-[500] text-[15px] leading-[20px] ">
          Çfarë përfshihet në servisimin e një veture? Servisimi i një veture
          mund të përfshijë kontrolle dhe rregullime të sistemeve në më shumë se
          50 përbërës duke përfshirë: Problemet mekanike dhe elektrike Ndërrimin
          e vajit të motorit apo zëvendësim të filterit
          <br />
          Kontrollim të dritave GomaveFunksionimit të frenave Akordimi i motorit
          tuaj
        </p>
      </div>
      <div className=" flex gap-[50px] bg-[#2A2A2A] relative w-[100%] h-[208px] justify-center items-center ">
        <h3 className=" font-[900] text-white absolute text-[15px] top-[40px] ">
          Orari i Punës:
        </h3>
        <div className=" flex flex-col items-center mt-[20px] ">
          <img src={clock} alt="" />
          <h4 className=" font-[500] text-[15px] text-white my-[5px] ">
            E Martë
          </h4>
          <h4 className=" font-[250] text-[10px] text-white ">08:00 - 17:00</h4>
        </div>
        <div className=" flex flex-col items-center mt-[20px] ">
          <img src={clock} alt="" />
          <h4 className=" font-[500] text-[15px] text-white my-[5px] ">
            E Mërkure
          </h4>
          <h4 className=" font-[250] text-[10px] text-white ">08:00 - 17:00</h4>
        </div>
        <div className=" flex flex-col items-center mt-[20px] ">
          <img src={clock} alt="" />
          <h4 className=" font-[500] text-[15px] text-white my-[5px] ">
            E Enjëte
          </h4>
          <h4 className=" font-[250] text-[10px] text-white ">08:00 - 17:00</h4>
        </div>
        <div className=" flex flex-col items-center mt-[20px] ">
          <img src={clock} alt="" />
          <h4 className=" font-[500] text-[15px] text-white my-[5px] ">
            E Hëne
          </h4>
          <h4 className=" font-[250] text-[10px] text-white ">08:00 - 17:00</h4>
        </div>
        <div className=" flex flex-col items-center mt-[20px] ">
          <img src={clock} alt="" />
          <h4 className=" font-[500] text-[15px] text-white my-[5px] ">
            E Premte
          </h4>
          <h4 className=" font-[250] text-[10px] text-white ">08:00 - 17:00</h4>
        </div>
        <div className=" flex flex-col items-center mt-[20px] ">
          <img src={clock} alt="" />
          <h4 className=" font-[500] text-[15px] text-white my-[5px] ">
            E Shtunë
          </h4>
          <h4 className=" font-[250] text-[10px] text-white ">08:00 - 17:00</h4>
        </div>
        <div className=" flex flex-col items-center mt-[20px] ">
          <img src={hand} alt="" />
          <h4 className=" font-[500] text-[15px] text-white my-[5px] ">
            E Diele
          </h4>
          <h4 className=" font-[250] text-[10px] text-white ">Mbyllur</h4>
        </div>
      </div>
      <div className=" px-[200px] ">
        <h2 className="z-5 text-white font-[900] mt-[100px] text-[40px] leading-[48px] mb-[20px] ">
          Brendet që i shërbejmë:
        </h2>
        <p className=" text-white z-5 font-[500] text-[15px] leading-[20px] ">
          Çfarë përfshihet në servisimin e një veture? Servisimi i një veture
          mund të përfshijë kontrolle dhe rregullime të sistemeve në më shumë se
          50 përbërës duke përfshirë
        </p>
        <div className=" flex gap-[4%] justify-center ">
          <div className=" relative flex flex-col z-[10] my-[40px] items-center w-[25%] h-[467px] ">
            <img src={car1} alt="" className=" my-[30px] " />
            <p className=" px-[40px] font-[500] text-[#A4A4A4] text-[10px] leading-[12px] ">
              Çfarë përfshihet në servisimin e një veture? Servisimi i një
              veture mund të përfshijë kontrolle dhe rregullime të sistemeve në
              më shumë se 50 përbërës duke përfshirë
            </p>
            <img
              className=" absolute object-cover w-[100%] z-[-1] h-[100%] "
              src={car1back}
              alt=""
            />
          </div>
          <div className=" relative flex flex-col z-[10] my-[40px] items-center w-[25%] h-[467px] ">
            <img src={car2} alt="" className=" my-[30px] " />
            <p className=" px-[40px] font-[500] text-[#A4A4A4] text-[10px] leading-[12px] ">
              Çfarë përfshihet në servisimin e një veture? Servisimi i një
              veture mund të përfshijë kontrolle dhe rregullime të sistemeve në
              më shumë se 50 përbërës duke përfshirë
            </p>
            <img
              className=" absolute object-cover w-[100%] z-[-1] h-[100%] "
              src={car2back}
              alt=""
            />
          </div>
          <div className=" relative flex flex-col z-[10] my-[40px] items-center w-[25%] h-[467px] ">
            <img src={car3} alt="" className=" my-[30px] " />
            <p className=" px-[40px] font-[500] text-[#A4A4A4] text-[10px] leading-[12px] ">
              Çfarë përfshihet në servisimin e një veture? Servisimi i një
              veture mund të përfshijë kontrolle dhe rregullime të sistemeve në
              më shumë se 50 përbërës duke përfshirë
            </p>
            <img
              className=" absolute object-cover w-[100%] z-[-1] h-[100%] "
              src={car3back}
              alt=""
            />
          </div>
          <div className=" relative flex flex-col z-[10] my-[40px] items-center w-[25%] h-[467px] ">
            <img src={car4} alt="" className=" my-[30px] " />
            <p className=" px-[40px] font-[500] text-[#A4A4A4] text-[10px] leading-[12px] ">
              Çfarë përfshihet në servisimin e një veture? Servisimi i një
              veture mund të përfshijë kontrolle dhe rregullime të sistemeve në
              më shumë se 50 përbërës duke përfshirë
            </p>
            <img
              className=" absolute object-cover w-[100%] z-[-1] h-[100%] "
              src={car4back}
              alt=""
            />
          </div>
        </div>
      </div>
      <div>
        <div className=" flex ">
          <img
            src={gallery1}
            className="w-[70%] h-[522px] object-cover "
            alt=""
          />
          <img
            src={gallery2}
            className="w-[30%] h-[522px] object-cover "
            alt=""
          />
        </div>
        <div className=" flex ">
          <img
            src={gallery4}
            className="w-[40%] h-[522px] object-cover "
            alt=""
          />
          <img
            src={gallery3}
            className="w-[60%] h-[522px] object-cover "
            alt=""
          />
        </div>
      </div>
      <div className="  relative w-[100%] aspect-[1.9] z-[10] flex flex-col justify-start mt-[80px] ml-[60px] overflow-hidden ">
        <img src={serviceGuy} className=" absolute z-[-1] left-0 w-[100%] " alt="" />
        <h2 className="z-5 text-white font-[900] text-[40px] leading-[48px] mb-[20px] ">
          SERVISI
        </h2>
        <p className=" text-white z-5 font-[500] text-[15px] leading-[20px] ">
        Çfarë përfshihet në servisimin e një veture? Servisimi i një veture mund të përfshijë kontrolle dhe rregullime të sistemeve në më shumë se 50 përbërës 
        duke përfshirë
        </p>
      </div>
    </div>
  );
};

export default Service;
