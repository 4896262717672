import React, { useState } from "react";
import Button from "../Button/Button";
import footerLogo from "../../assets/footerLogo.png";
import axios from "axios";
import { baseURL } from "../../utils/api";

const Footer = () => {
  const currentUrl = window.location.pathname;
  const [submitting, setSubmitting] = useState(false);
  const [emailSubmitting, setEmailSubmitting] = useState(false);

  function sendEmailSub() {
    setEmailSubmitting(true);
    let data = JSON.stringify({
      data: {
        EmailSub: document.getElementById("EmailSub").value===""?undefined:document.getElementById("EmailSub").value,
      },
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: baseURL+'/email-subscriptions',
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        window.alert("Successfully subscribed!");
      })
      .catch((error) => {
        setEmailSubmitting(false);
        console.log(error?.response?.data?.error);
        document.getElementById("EmailSub").style.borderColor = "#ff0000";
        document.getElementById("EmailSub").style.borderWidth = "1px";
        document.getElementById("EmailSub").style.borderStyle = "solid";
      });
  }

  function sendMessage() {
    setSubmitting(true);
    let data = JSON.stringify({
      "data": {
        "Name": document.getElementById("Name").value===""?undefined:document.getElementById("Name").value,
        "Email": document.getElementById("Email").value===""?undefined:document.getElementById("Email").value,
        "Comment": document.getElementById("Comment").value===""?undefined:document.getElementById("Comment").value,
      },
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: baseURL+'/messages',
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        window.alert("Message has been sendt, we will contact you shortly!");
      })
      .catch((error) => {
        console.log(error?.response?.data?.error)
        setSubmitting(false);
        try {
          for (let fail of error.response.data.error.details.errors) {
            document.getElementById(fail.path[0]+"Wrapper").style.borderColor = "#ff0000";
          }
        } catch (err) {
          console.log("failed to set border colors")
        }
      });
  }

  if (currentUrl === "/") {
    return <></>;
  }
  return (
    <div id="footerWrapper" className=" z-[1000] relative bg-[#3f3e40] flex flex-col w-[100%] px-[20%] md:px-[5%] items-center pb-[240px] ">
      <div className=" bg-[#f5f5f5] absolute rounded-xl overflow-clip flex flex-col items-center pt-[24px] max-w-[90vw] w-[420px] mt-[-50px] ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 42 42"
          width="42"
          height="42"
          style={{ fill: "#0095da" }}
        >
          <g id="Footer">
            <g id="Subscribe">
              <path
                id="Icon"
                class="shp0"
                d="M41.64 15.24L21.54 0.18C21.22 -0.06 20.78 -0.06 20.46 0.18L0.36 15.24C0.13 15.41 0 15.67 0 15.95L0 15.95L0 15.95L0 41.11C0 41.6 0.4 42 0.89 42L41.11 42C41.6 42 42 41.6 42 41.11L42 15.95C42 15.67 41.87 15.41 41.64 15.24L41.64 15.24ZM21 2.01L39.62 15.95L33.42 20.59L33.42 12.09L8.58 12.09L8.58 20.59L2.38 15.95L21 2.01ZM31.64 21.93L21 29.9L10.36 21.93L10.36 13.88L31.64 13.88L31.64 21.93ZM1.79 40.21L1.79 17.74L20.46 31.73C20.92 32.05 21.38 31.85 21.54 31.73L40.21 17.74L40.21 40.21L1.79 40.21ZM1.79 40.21M15.89 17.01L26.28 17.01L26.28 18.8L15.89 18.8L15.89 17.01ZM15.89 21.03L26.28 21.03L26.28 22.81L15.89 22.81L15.89 21.03Z"
              ></path>
            </g>
          </g>
        </svg>
        <h2 className=" font-[200] text-[52px] ">Na ndiqni</h2>
        <p className=" font-[700] tracking-widest text-[#9fa3a7] text-[14px] text-center md:text-[10px] ">
          DHE INFORMOHUNI PËR TË REJAT
        </p>
        <div id="EmailSubWrapper" className=" mt-[5px] w-[101%] h-[56px] ">
          <input
            id="EmailSub"
            placeholder="Emaili"
            className=" w-[65%] h-[100%] p-[16px] text-[#444] "
            onChange={(e) => {
              e.target.style.borderColor = "";
              e.target.style.borderWidth = "";
              e.target.style.borderStyle = "";
            }}
          />
          <button
            onClick={sendEmailSub}
            disabled={emailSubmitting}
            className=" w-[35%] bg-[#0094da] border-[#0094da] border-[2px] border-solid hover:text-[#0094da] rounded-br-xl duration-[0.1s] hover:bg-[#fff] h-[100%] text-[#fff] font-[700] text-[15px] "
          >
            SUBSCRIBE
          </button>
        </div>
      </div>
      <div className="flex w-[100%] mt-[210px] md:flex-col">
        <div className=" w-[50%] md:w-[100%] flex flex-col items-start ">
          <h3 className=" font-[400] text-[36px] w-[100%] text-white text-left mb-[40px] md:mb-[20px] ">
            Kontakti
          </h3>
          <p className=" text-[13px] text-white mb-[20px] w-[100%] ">
            Bërnicë e Epërme, 10000 Prishtinë
          </p>
          <a
            className=" w-[200px] flex justify-between items-center group hover:text-white"
            href="tel:+38344777775"
          >
            Servisim:
            <span className=" text-[#0094da] text-[14px] font-[700] group-hover:text-white ">
              +383 44 777 775
            </span>
          </a>
          <a
            href="tel:+38348170100"
            className=" w-[200px] flex justify-between items-center group hover:text-white "
          >
            Shitje:
            <span className=" text-[#0094da] text-[14px] font-[700] group-hover:text-white ">
              +383 48 170 100
            </span>
          </a>
          <a
            href="mailto:info@autoenergjia.com"
            className=" text-[#0094da] text-[14px] font-[700] hover:text-white "
          >
            info@autoenergjia.com
          </a>
        </div>
        <div className=" w-[50%] md:w-[100%] md:mt-[20px] ">
          <div className=" flex mb-[30px] gap-[10px] ">
            <div id="NameWrapper" className=" relative flex-grow flex items-center rounded-md overflow-clip border-solid border-[#ced4da] border-[1px] ">
              <svg
                className="absolute fill-[#9fa3a7] left-[10px] "
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 12 16"
                width="12"
                height="16"
              >
                <path
                  id="Ellipse"
                  class=""
                  d="M6 0C7.98 0 9.59 1.61 9.59 3.59C9.59 5.58 7.98 7.19 6 7.19C4.02 7.19 2.41 5.58 2.41 3.59C2.41 1.61 4.02 0 6 0ZM6 8C9.31 8 12 10.95 12 13.6C12 16.25 9.31 16 6 16C2.69 16 0 16.25 0 13.6C0 10.95 2.69 8 6 8Z"
                ></path>
              </svg>
              <input
                id="Name"
                onChange={(e) => {e.target.style.borderColor = ""}}
                className=" text-[#6c757d] p-[12px] pl-[30px] w-[100%] h-[100%] "
                placeholder="Emri"
              />
            </div>
            <div id="EmailWrapper" className=" relative flex flex-grow items-center rounded-md overflow-clip border-solid border-[#ced4da] border-[1px] ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 18 15"
                width="18"
                height="15"
                style={{ position: "absolute", fill: "#9fa3a7", left: "10px" }}
              >
                <path
                  id="Icon"
                  d="M1.73 4.62C1.96 4.79 2.64 5.3 3.79 6.14C4.93 6.98 5.81 7.63 6.42 8.09C6.49 8.14 6.63 8.24 6.85 8.41C7.06 8.58 7.24 8.71 7.39 8.82C7.53 8.92 7.71 9.03 7.91 9.16C8.11 9.29 8.31 9.39 8.49 9.45C8.67 9.51 8.84 9.55 8.99 9.55L9 9.55L9.01 9.55C9.16 9.55 9.33 9.51 9.51 9.45C9.69 9.39 9.89 9.29 10.09 9.16C10.29 9.03 10.47 8.92 10.61 8.82C10.76 8.71 10.94 8.58 11.15 8.41C11.37 8.24 11.51 8.14 11.58 8.09C12.2 7.63 13.76 6.48 16.28 4.62C16.77 4.26 17.18 3.82 17.51 3.31C17.84 2.8 18 2.26 18 1.7C18 1.23 17.84 0.83 17.52 0.5C17.2 0.16 16.83 0 16.39 0L1.61 0C1.09 0 0.69 0.18 0.42 0.55C0.14 0.92 0 1.38 0 1.94C0 2.38 0.18 2.87 0.55 3.39C0.92 3.91 1.31 4.32 1.73 4.62ZM17 5.76C14.8 7.34 13.13 8.57 11.99 9.44C11.61 9.74 11.3 9.97 11.06 10.14C10.83 10.3 10.51 10.47 10.11 10.65C9.72 10.82 9.35 10.91 9.01 10.91L9 10.91L8.99 10.91C8.65 10.91 8.28 10.82 7.88 10.65C7.49 10.47 7.17 10.3 6.94 10.14C6.7 9.97 6.39 9.74 6.01 9.44C5.1 8.74 3.44 7.51 1.01 5.76C0.63 5.49 0.29 5.18 0 4.84L0 13.3C0 13.77 0.16 14.17 0.47 14.5C0.79 14.83 1.17 15 1.61 15L16.39 15C16.83 15 17.21 14.83 17.53 14.5C17.84 14.17 18 13.77 18 13.3L18 4.84C17.71 5.18 17.38 5.49 17 5.76Z"
                ></path>
              </svg>
              <input
                id="Email"
                onChange={(e) => {e.target.style.borderColor = ""}}
                className=" text-[#6c757d] p-[12px] pl-[34px] w-[100%] h-[100%] "
                placeholder="Emaili"
              />
            </div>
          </div>
          <div id="CommentWrapper" className=" relative flex items-center rounded-md overflow-clip border-solid mb-[40px] border-[#ced4da] border-[1px] ">
            <textarea
              id="Comment"
              onChange={(e) => {e.target.style.borderColor = ""}}
              className=" text-[#6c757d] p-[12px] resize-none w-[100%] h-[150px] "
              placeholder="Komenti juaj:"
            />
          </div>
          <Button func={sendMessage} disabled={submitting} backgroundColor={"#0094da"}>DËRGO</Button>
        </div>
      </div>
      <div className=" mb-[40px] pt-[30px] flex flex-col items-center gap-[25px] absolute w-[70%] border-t-[3px] border-solid border-[#2f2d2f] bottom-0 ">
        <img className=" h-[48px] w-[106px] " src={footerLogo} alt="" />
        <p className=" text-white font-[600] text-[14px] ">
          {" "}
          AUTO ENERGJIA© {new Date().getFullYear()} All Rights Reserved{" "}
        </p>
      </div>
    </div>
  );
};

export default Footer;
