import React, { useEffect, useState } from "react";
import Box from "../../Components/Box/Box";
import OfferPreview from "../../Components/OfferPreview/OfferPreview";
import styles from "./OffersPage.module.scss";
import { getOffers } from "../../utils/api";

const OffersPage = () => {
  const [brand, setbrand] = useState("all");
  const [transmission, setTransmission] = useState("all");
  const [year, setYear] = useState("all");
  const [offerData, setOfferData] = useState([]);

  const [apiData, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getOffers();
        setData(data.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    let newList = [];
    let temp_list = [...apiData];
    while (temp_list.length > 0) {
      let offer = temp_list.pop();
      let passed = true;
      if (brand !== "all" && offer.brand !== brand) {
        passed = false;
      }
      if (year !== "all" && offer.year !== year) {
        passed = false;
      }
      if (transmission !== "all" && !offer[transmission]) {
        passed = false;
      }

      if (passed) {
        newList.push(offer);
      }
    }

    setOfferData(newList);
  }, [brand, transmission, year, apiData]);

  return (
    <div className=" mb-[100px] ">
      <Box pageTitle={"VETURAT"}>
        <h1 className="text-white md:text-[40px] text-center ">
          VETURAT NË SHITJE
        </h1>
        <p className="text-white text-center text-[24px] max-w-[450px] md:text-[20px] ">
          Modelet e veturave të përdorura e të garantuara në shitje
        </p>
      </Box>
      <div className=" flex md:flex-col gap-[10px] w-[100%] justify-center mt-[50px] ">
        <div className="flex flex-col items-start w-[15%] md:w-[100%] md:px-[10px] ">
          <h3>Filtro veturat</h3>
          <h5 className=" mb-[10px] mt-[15px] ">Marka</h5>
          <select
            value={brand}
            onChange={(e) => {
              setbrand(e.target.value);
            }}
            className={styles.optionsDropDown}
          >
            <option value={"all"}>Të Gjitha</option>
            {brandOptions.map((data, index) => (
              <option key={index} value={data}>
                {data}
              </option>
            ))}
          </select>
          <h5 className=" mb-[10px] mt-[15px] ">Transmisioni</h5>
          <select
            value={transmission}
            onChange={(e) => {
              setTransmission(e.target.value);
            }}
            className={styles.optionsDropDown}
          >
            <option value={"all"}>Të Gjitha</option>
            {transmissionOptions.map((data, index) => (
              <option key={index} value={data}>
                {data}
              </option>
            ))}
          </select>
          <h5 className=" mb-[10px] mt-[15px] ">Viti</h5>
          <select
            value={year}
            onChange={(e) => {
              setYear(e.target.value);
            }}
            className={styles.optionsDropDown}
          >
            <option value={"all"}>Të Gjitha</option>
            {yearOptions.map((data, index) => (
              <option key={index} value={data}>
                {data}
              </option>
            ))}
          </select>
          <div className=" mt-[50px] "></div>
        </div>
        <div className=" flex flex-wrap gap-[15px] w-[65%] md:w-[100%] justify-center ">
          {offerData.map((data, index) => (
            <OfferPreview data={data} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OffersPage;

const brandOptions = [
  "BMW",
  "Golf",
  "Audi",
  "Toyota",
  "Volvo",
  "DAIMLER AG",
  "Mercedes-Benz",
  "Volkswagen",
];

const transmissionOptions = ["Automatik", "Manual"];

const yearOptions = [
  "2009",
  "2010",
  "2011",
  "2012",
  "2013",
  "2014",
  "2015",
  "2016",
];
