import React, { useEffect, useRef, useState } from "react";
import Box from "../../Components/Box/Box";
import facebook from "../../assets/thin_facebook.png";
import twitter from "../../assets/thin_twitter.png";
import linkedIn from "../../assets/thin_linkedin.png";
import instagram from "../../assets/thin_instagram.png";
import Button from "../../Components/Button/Button";
import styles from "./Offer.module.scss";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination, Zoom } from "swiper/modules";
import { getOffer, getOffers } from "../../utils/api";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import OfferPreview from "../../Components/OfferPreview/OfferPreview";

const Offer = () => {
  const [apiData, setData] = useState({});
  const [otherOffers, setOtherOffers] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getOffer(window.location.pathname.split("/")[2]);
        setData(data.data);
        const otherData = await getOffers();
        let list = otherData.data;
        for (let i in list) {
          if (list[i].id === data.data.id) {
            console.log(data.data);
            list.splice(i, 1);
          }
        }

        while (list.length > 4) {
          list.splice(Math.floor(list.length * Math.random()), 1);
        }

        console.log(list);

        setOtherOffers(list);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const [mobile, setMobile] = useState(window.innerWidth < 768);
  function checkResolution() {
    setMobile(window.innerWidth < 768);
  }

  window.addEventListener("resize", checkResolution);

  //this function is for adding spaces every third character in a number
  function formatPrice(str, divider) {
    if (!str) return;
    let formattedStr = str.toString(); //converts number to str
    let revArray = []; //instances an empty array
    for (let char of formattedStr) {
      //loops through the str to get each character
      revArray.push(char); //pushes character to earlier array
    }
    revArray.reverse(); //reverses the array
    let array = [];
    for (let index in revArray) {
      //loops over revArray for index
      array.push(revArray[index]); //pushes the character from earlier array to new array
      if (index % 3 === 2 && index !== array.length) {
        //if the 3rd modulus of index equals 2, push a space
        array.push(divider);
      }
    }
    array.reverse(); //reverse the new array to get back to forwards
    let finalStr = "";
    for (let char of array) {
      //finally loop over the new array and push each character into a string
      finalStr += char;
    }

    if (finalStr[0] === divider) {
      finalStr = finalStr.replace(divider, "");
    }

    return finalStr;
  }

  const bigPictureRef = useRef(null);
  const [isZoomed, setIsZoomed] = useState(false);

  function checkZoomLevel(swiper, scale) {
    if (scale === 1) {
      setIsZoomed(false);
    } else {
      setIsZoomed(true);
    }
  }

  return (
    <div>
      <Box pageTitle={"BRENDET"} boxDisabled={true} />
      <div className=" py-[30px] w-[100%] flex flex-col items-center justify-start relative ">
        <div
          style={{
            height: !mobile
              ? isZoomed
                ? "850px"
                : "700px"
              : isZoomed
              ? "500px"
              : "300px",
            padding: isZoomed ? "0 20px" : "",
          }}
          className=" relative w-[100%] "
        >
          <svg
            style={{ left: !isZoomed ? (mobile ? "4.5%" : "17.3%") : "25px" }}
            className="absolute z-10 size-[50px] top-[5px] "
            onClick={() => {
              if (!isZoomed) {
                bigPictureRef.current.swiper.zoom.in();
              } else {
                bigPictureRef.current.swiper.zoom.out();
              }
            }}
            viewBox="-7.14 -7.14 35.28 35.28"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            fill="#ffffff"
          >
            <g
              id="SVGRepo_bgCarrier"
              stroke-width="0"
              transform="translate(0,0), scale(1)"
            >
              <rect
                x="-7.14"
                y="-7.14"
                width="35.28"
                height="35.28"
                rx="17.64"
                fill="#000000"
                strokewidth="0"
              ></rect>
            </g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke="#ffffff"
              stroke-width="2.646"
            >
              {" "}
              <title>zoom_in [#1462]</title> <desc>Created with Sketch.</desc>{" "}
              <defs> </defs>{" "}
              <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                {" "}
                <g
                  id="Dribbble-Light-Preview"
                  transform="translate(-379.000000, -440.000000)"
                  fill="#ffffff"
                >
                  {" "}
                  <g id="icons" transform="translate(56.000000, 160.000000)">
                    {" "}
                    <path
                      d="M332.449994,286.967331 L334.549993,286.967331 C335.129593,286.967331 335.599993,287.416448 335.599993,287.969825 C335.599993,288.523201 335.129593,288.972319 334.549993,288.972319 L332.449994,288.972319 L332.449994,290.977306 C332.449994,291.530683 331.979595,291.9798 331.399995,291.9798 C330.820395,291.9798 330.349996,291.530683 330.349996,290.977306 L330.349996,288.972319 L328.249997,288.972319 C327.670397,288.972319 327.199998,288.523201 327.199998,287.969825 C327.199998,287.416448 327.670397,286.967331 328.249997,286.967331 L330.349996,286.967331 L330.349996,284.962344 C330.349996,284.408967 330.820395,283.95985 331.399995,283.95985 C331.979595,283.95985 332.449994,284.408967 332.449994,284.962344 L332.449994,286.967331 Z M343.692338,299.706019 L343.692338,299.706019 C343.282838,300.097994 342.617138,300.097994 342.207639,299.706019 L338.060141,295.746169 L339.54484,294.328642 L343.692338,298.288493 C344.102887,298.679465 344.102887,299.315046 343.692338,299.706019 L343.692338,299.706019 Z M331.399995,294.034912 C327.926597,294.034912 325.099999,291.337201 325.099999,288.01995 C325.099999,284.7037 327.926597,282.004987 331.399995,282.004987 C334.873393,282.004987 337.699991,284.7037 337.699991,288.01995 C337.699991,291.337201 334.873393,294.034912 331.399995,294.034912 L331.399995,294.034912 Z M331.399995,280 C326.761098,280 323,283.590932 323,288.01995 C323,292.449969 326.761098,296.039899 331.399995,296.039899 C336.038892,296.039899 339.79999,292.449969 339.79999,288.01995 C339.79999,283.590932 336.038892,280 331.399995,280 L331.399995,280 Z"
                      id="zoom_in-[#1462]"
                    >
                      {" "}
                    </path>{" "}
                  </g>{" "}
                </g>{" "}
              </g>{" "}
            </g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <title>zoom_in [#1462]</title> <desc>Created with Sketch.</desc>{" "}
              <defs> </defs>{" "}
              <g
                id="Page-1"
                stroke-width="0.00021000000000000004"
                fill="none"
                fill-rule="evenodd"
              >
                {" "}
                <g
                  id="Dribbble-Light-Preview"
                  transform="translate(-379.000000, -440.000000)"
                  fill="#ffffff"
                >
                  {" "}
                  <g id="icons" transform="translate(56.000000, 160.000000)">
                    {" "}
                    <path
                      d="M332.449994,286.967331 L334.549993,286.967331 C335.129593,286.967331 335.599993,287.416448 335.599993,287.969825 C335.599993,288.523201 335.129593,288.972319 334.549993,288.972319 L332.449994,288.972319 L332.449994,290.977306 C332.449994,291.530683 331.979595,291.9798 331.399995,291.9798 C330.820395,291.9798 330.349996,291.530683 330.349996,290.977306 L330.349996,288.972319 L328.249997,288.972319 C327.670397,288.972319 327.199998,288.523201 327.199998,287.969825 C327.199998,287.416448 327.670397,286.967331 328.249997,286.967331 L330.349996,286.967331 L330.349996,284.962344 C330.349996,284.408967 330.820395,283.95985 331.399995,283.95985 C331.979595,283.95985 332.449994,284.408967 332.449994,284.962344 L332.449994,286.967331 Z M343.692338,299.706019 L343.692338,299.706019 C343.282838,300.097994 342.617138,300.097994 342.207639,299.706019 L338.060141,295.746169 L339.54484,294.328642 L343.692338,298.288493 C344.102887,298.679465 344.102887,299.315046 343.692338,299.706019 L343.692338,299.706019 Z M331.399995,294.034912 C327.926597,294.034912 325.099999,291.337201 325.099999,288.01995 C325.099999,284.7037 327.926597,282.004987 331.399995,282.004987 C334.873393,282.004987 337.699991,284.7037 337.699991,288.01995 C337.699991,291.337201 334.873393,294.034912 331.399995,294.034912 L331.399995,294.034912 Z M331.399995,280 C326.761098,280 323,283.590932 323,288.01995 C323,292.449969 326.761098,296.039899 331.399995,296.039899 C336.038892,296.039899 339.79999,292.449969 339.79999,288.01995 C339.79999,283.590932 336.038892,280 331.399995,280 L331.399995,280 Z"
                      id="zoom_in-[#1462]"
                    >
                      {" "}
                    </path>{" "}
                  </g>{" "}
                </g>{" "}
              </g>{" "}
            </g>
          </svg>
          <Swiper
            ref={bigPictureRef}
            zoom={true}
            spaceBetween={30}
            centeredSlides={true}
            loop={true}
            slidesPerView={isZoomed ? 1 : mobile ? 1.05 : 1.5}
            className="h-[95%] w-[100%] mb-[5px]"
            modules={[Zoom, Pagination]}
            onZoomChange={checkZoomLevel}
            pagination={{
              clickable: true,
              bulletClass: styles.bulletClass,
              bulletActiveClass: styles.activeBulletClass,
              el: `#offerPaginationWrapper`,
            }}
          >
            {apiData?.gallery?.map((data, index) => (
              <SwiperSlide>
                <div className=" swiper-zoom-container w-[100%] h-[100%] ">
                  <img
                    src={data.url}
                    alt=""
                    key={index}
                    className=" w-[100%] rounded-3xl h-[100%] object-cover relative "
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div
            id="offerPaginationWrapper"
            className="select-none flex-wrap mt-[20px] flex justify-center gap-[10px]"
          >
            <div className={styles.activeBulletClass} />
          </div>
        </div>
        <div className=" w-[90%] md:mt-[100px] text-[#212529] ">
          <h3 className="text-left text-[32px] font-[700] mb-[10px] ">
            {apiData.offerTitle}
          </h3>
          <div className="flex gap-[10px] justify-center flex-wrap ">
            {!apiData.year ? (
              <></>
            ) : (
              <p className=" font-[600] text-[18px] flex flex-col items-center w-[170px] text-center flex-shrink-0 ">
                Viti: <span className="font-[400] ">{apiData.year}</span>
              </p>
            )}
            {!apiData.km ? (
              <></>
            ) : (
              <p className=" font-[600] text-[18px] flex flex-col items-center w-[170px] text-center flex-shrink-0 ">
                KM:{" "}
                <span className="font-[400] ">
                  {formatPrice(apiData.km, ",")}
                </span>
              </p>
            )}
            {!apiData.motor ? (
              <></>
            ) : (
              <p className=" font-[600] text-[18px] flex flex-col items-center w-[170px] text-center flex-shrink-0 ">
                Motori: <span className="font-[400] ">{apiData.motor}</span>
              </p>
            )}
            {!apiData.consumption ? (
              <></>
            ) : (
              <p className=" font-[600] text-[18px] flex flex-col items-center w-[170px] text-center flex-shrink-0 ">
                Konsumi:{" "}
                <span className="font-[400] ">{apiData.consumption}</span>
              </p>
            )}
            {!apiData.battery ? (
              <></>
            ) : (
              <p className=" font-[600] text-[18px] flex flex-col items-center w-[170px] text-center flex-shrink-0 ">
                Fuqia: <span className="font-[400] ">{apiData.battery}</span>
              </p>
            )}
            {!apiData.look ? (
              <></>
            ) : (
              <p className=" font-[600] text-[18px] flex flex-col items-center w-[170px] text-center flex-shrink-0 ">
                Ngjyra: <span className="font-[400] ">{apiData.look}</span>
              </p>
            )}
          </div>
          <p className=" font-[600] text-[26px] mt-[30px] ">
            Pajisjet e vetures:{" "}
            <span className={styles.blockRenderer}>
              {!apiData?.description ? (
                <></>
              ) : (
                <BlocksRenderer content={apiData.description} />
              )}
            </span>
          </p>
          <div className="flex gap-[5px] mt-[10px] mb-[10px] ">
            <img className="size-[40px] " src={facebook} alt="" />
            <img className="size-[40px] " src={twitter} alt="" />
            <img className="size-[40px] " src={linkedIn} alt="" />
            <img className="size-[40px] " src={instagram} alt="" />
          </div>
          {apiData.sold ? (
            <h4 style={{background: "linear-gradient(90deg, rgba(222, 0, 36, 0.12) 0%, rgba(222, 0, 36, 0) 23%)"}} className=" font-[700] text-[#de0f30] relative left-[-6%] pl-[6%] ">E SHITUR</h4>
          ) : (
            <>
              <h4>€{formatPrice(apiData.price, ",")}.00</h4>
              <div className=" h-[41px] flex items-center gap-[3px] ">
                <Button
                  backgroundColor={"#0094da"}
                  func={() =>
                    window.scrollTo({ behavior: "smooth", top: 9999999 })
                  }
                >
                  Na kontaktoni
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
      <div className=" flex pt-[20px] gap-[10px] justify-start px-[100px] md:px-[10px] pb-[10px] overflow-scroll mt-[200px] md:mt-[20px] mb-[80px]  ">
        {otherOffers?.map((data, index) => (
          <OfferPreview data={data} key={index} square={true} />
        ))}
      </div>
    </div>
  );
};

export default Offer;
