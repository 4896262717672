import React from 'react'
import Box from '../../Components/Box/Box'
import InfoBar from '../../Components/InfoBar/InfoBar'

const InfoBox = ({color,icon,title,desc,image}) => {
  return (
    <div className='flex flex-col items-center mb-[130px] ' >
      <Box pageTitle={"SHËRBIMET"} boxDisabled={true} />
      <div style={{backgroundColor:color}} className=' mt-[-80px] w-[70%] flex flex-col items-center pt-[20px] '>
        {icon}
        <h1 className=' mb-[20px] uppercase text-white ' >{title}</h1>
        <p className=' mb-[20px] text-center text-white w-[70%] ' >{desc}</p>
        <img src={image} alt=''/>
      </div>
      <div className=' bg-[#dddddd] h-[2px] w-[30%] mt-[60px] mb-[10px] '/>
      <InfoBar/>
    </div>
  )
}

export default InfoBox