import React, { useRef, useState } from "react";
import Box from "../../Components/Box/Box";
import FiveOff from "../../assets/Service5%off.png";
import Button from "../../Components/Button/Button";
import axios from "axios";
import { baseURL } from "../../utils/api";

const Termin = () => {

  function sendToBack() {
    setSubmitting(true)
    let data = JSON.stringify({
      "data": {
        "Name": NameRef.current.value,
        "Number": NumberRef.current.value.replaceAll(" ",""),
        "Email": EmailRef.current.value,
        "carType": TypeRef.current.value,
        "year": YearRef.current.value,
        "bodyNumber": BodyNumberRef.current.value,
        "message": MessageRef.current.value
      }
    });
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: baseURL+'/appointments',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      window.alert("Appointment has been successfully sendt! you will recieve an email with more details soon.")
      window.open("/sherbimet","_self")
    })
    .catch((error) => {
      setSubmitting(false)
      try {
        for(let fail of error.response.data.error.details.errors) {
          document.getElementById(fail.path[0]).style.borderColor = "#ff0000"
        }
      } catch (err) {
        console.log(error)
        console.log("failed to set border color")
      }
    });
  }

  const [submitting,setSubmitting] = useState(false)

  const NameRef = useRef(null)
  const NumberRef = useRef(null)
  const EmailRef = useRef(null)
  const TypeRef = useRef(null)
  const YearRef = useRef(null)
  const BodyNumberRef = useRef(null)
  const MessageRef = useRef(null)
  
  return (
    <div>
      <Box pageTitle={"CAKTO TERMIN"} />
      <div className=" mb-[100px] flex items-center flex-col ">
        <img
          src={FiveOff}
          className=" aspect-[0.877192982] h-[100px] mt-[-15px] "
          alt=""
        />
        <h2 className="font-[700] ">ORARI I PUNËS</h2>
        <p>E hënë - E Premte</p>
        <p className=" font-[700] ">08:00 - 17:00</p>
        <p className="mt-[20px] ">E Shtunë</p>
        <p className=" font-[700] mb-[10px]">08.00 - 12:00h</p>
        <div className=" flex flex-wrap gap-[10px] mb-[20px] justify-center max-w-[520px] px-[10px] ">
          <input
            onChange={(e) => e.target.style.borderColor = ""}
            ref={NameRef}
            id="Name"
            className=" rounded-md border-[#ced4da] border-[1px] flex-grow w-[200px] border-solid p-[12px] "
            placeholder="Emri Mbiemri"
          />
          <input
            onChange={(e) => e.target.style.borderColor = ""}
            ref={NumberRef}
            id="Number"
            className=" rounded-md border-[#ced4da] border-[1px] flex-grow w-[200px] border-solid p-[12px] "
            placeholder="Numri i tel"
          />
          <input
            onChange={(e) => e.target.style.borderColor = ""}
            ref={EmailRef}
            id="Email"
            className=" rounded-md border-[#ced4da] border-[1px] flex-grow w-[200px] border-solid p-[12px] "
            placeholder="Emaili"
          />
          <input
            onChange={(e) => e.target.style.borderColor = ""}
            ref={TypeRef}
            id="carType"
            className=" rounded-md border-[#ced4da] border-[1px] flex-grow w-[200px] border-solid p-[12px] "
            placeholder="Lloji i veturës"
          />
          <input
            onChange={(e) => e.target.style.borderColor = ""}
            ref={YearRef}
            id="year"
            className=" rounded-md border-[#ced4da] border-[1px] flex-grow w-[200px] border-solid p-[12px] "
            placeholder="Viti"
          />
          <input
            onChange={(e) => e.target.style.borderColor = ""}
            ref={BodyNumberRef}
            id="bodyNumber"
            className=" rounded-md border-[#ced4da] border-[1px] flex-grow w-[200px] border-solid p-[12px] "
            placeholder="Numri i shasisë"
          />
          <textarea
            onChange={(e) => e.target.style.borderColor = ""}
            ref={MessageRef}
            id="message"
            className=" resize-none rounded-md border-[#ced4da] border-[1px] min-h-[120px] flex-grow border-solid p-[12px] "
            placeholder="Përshkrimi"
          />
        </div>
        <Button disabled={submitting} func={sendToBack} backgroundColor={"#0094da"}>DËRGO</Button>
      </div>
    </div>
  );
};

export default Termin;
