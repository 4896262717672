import axios from "axios";

export const baseURL = 'https://auto-energjia-back.onrender.com/api' 
//export const baseURL = 'http://localhost:1337/api' 

const axiosInstance = axios.create({
  baseURL: baseURL,
});

export const getOffers = async () => {
  const response = await axiosInstance.get(
    `/offers?populate=*`
  );
  return response?.data ?? null;
};

export const getOffer = async (id) => {
  const response = await axiosInstance.get(
    `/offers/${id}?populate=*`
  );
  return response?.data ?? null;
};

export const getReviews = async () => {
  const response = await axiosInstance.get(
    `/reviews?populate=*`
  );
  return response?.data ?? null;
};

export const getGallery = async () => {
  const response = await axiosInstance.get(
    `/gallery?populate=*`
  );
  return response?.data ?? null;
};