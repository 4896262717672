import React from "react";
import DilemmaServiceImg from "../../assets/dilemmaServiceImg.png";
import DilemmaOfferImg from "../../assets/dilemmaOfferImg.png";

const Dilemma = () => {
  return (
    <div className="flex w-[100%] h-[100dvh] md:flex-col ">
      <div
        onClick={() => window.open("/sherbimet", "_self")}
        className="flex items-center justify-center w-[50%] md:w-[100%] h-[100%] cursor-pointer relative group "
      >
        <img
          src={DilemmaServiceImg}
          alt=""
          className=" absolute z-[-1] object-cover h-[100%] w-[100%] grayscale-[100%] group-hover:grayscale-[0%] transition-[filter] group-hover:duration-[0.1s] hover:duration-[1.5s]"
        />
        <h2 className="text-white ">SERVISI</h2>
        <div
        style={{left:"calc(50% - 0px)",top:"calc(50% + 50px)"}}
        className=" opacity-0 group-hover:opacity-[1] duration-[0.3s] flex items-center justify-center absolute ">
          <div className=' rounded-full size-[60px] blur-lg bg-[rgba(255,255,255,0.5)] absolute z-1 ' />
          <svg
            className='absolute size-[40px] z-1 mb-[4px] '
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.2929 4.29289C12.6834 3.90237 13.3166 3.90237 13.7071 4.29289L20.7071 11.2929C21.0976 11.6834 21.0976 12.3166 20.7071 12.7071L13.7071 19.7071C13.3166 20.0976 12.6834 20.0976 12.2929 19.7071C11.9024 19.3166 11.9024 18.6834 12.2929 18.2929L17.5858 13H4C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11H17.5858L12.2929 5.70711C11.9024 5.31658 11.9024 4.68342 12.2929 4.29289Z"
                fill="#fff"
              ></path>{" "}
            </g>
          </svg>
        </div>
      </div>
      <div
        onClick={() => window.open("/veturat", "_self")}
        className="flex items-center justify-center cursor-pointer w-[50%] md:w-[100%] h-[100%] relative group "
      >
        <img
          src={DilemmaOfferImg}
          alt=""
          className=" absolute z-[-1] object-cover h-[100%] w-[100%] grayscale-[100%] group-hover:grayscale-[0%] transition-[filter] group-hover:duration-[0.1s] hover:duration-[1.5s]"
        />
        <h2 className="text-white ">VETURAT</h2>
        <div
        style={{left:"calc(50% - 0px)",top:"calc(50% + 50px)"}}
        className=" opacity-0 group-hover:opacity-[1] duration-[0.3s] flex items-center justify-center absolute ">
          <div className=' rounded-full size-[60px] blur-lg bg-[rgba(255,255,255,0.5)] absolute z-1 ' />
          <svg
            className='absolute size-[40px] z-1 mb-[4px] '
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.2929 4.29289C12.6834 3.90237 13.3166 3.90237 13.7071 4.29289L20.7071 11.2929C21.0976 11.6834 21.0976 12.3166 20.7071 12.7071L13.7071 19.7071C13.3166 20.0976 12.6834 20.0976 12.2929 19.7071C11.9024 19.3166 11.9024 18.6834 12.2929 18.2929L17.5858 13H4C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11H17.5858L12.2929 5.70711C11.9024 5.31658 11.9024 4.68342 12.2929 4.29289Z"
                fill="#fff"
              ></path>{" "}
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Dilemma;
